import React from 'react'
import { MainLogo } from '../../Assets/Icons/generated'
import Dwhite from "../../Assets/Icons/dblack.svg"
import Get from "../../Assets/Icons/appb.svg"
import Social1 from "../../Assets/Icons/social1.svg"
import Social2 from "../../Assets/Icons/social2.svg"
import Social3 from "../../Assets/Icons/social3.svg"
import Social4 from "../../Assets/Icons/social4.svg"

export const Footer = () => {
  return (
    <div className='w-full bg-fp-main flex items-center justify-center flex-col space-y-12 p-11 md:px-[70px] xl:px-[144px] px-7'>
        <section className='w-full flex flex-col lg:flex-row items-start justify-between space-x-0 lg:space-x-12 space-y-12 lg:space-y-0'>
            <article className='space-y-4'>
                <div className='flex items-start space-x-4 text-fp-white'>
                    <MainLogo />
                    <div className='flex items-start flex-col'>
                        <h3 className='text-lg lg:text-xl font-bold'>Thinkspace</h3>
                        <span className='text-xs font-normal'>Fourpoints by Sheraton</span>
                    </div>
                </div>
                <div className='space-y-4 flex items-start flex-col'>
                    <span className='text-fp-white'>Download the app by clicking the link below :</span>
                    <div className='flex items-center justify-center space-x-4'>
                        <a href='https://play.google.com/store/apps/details?id=com.thethinkspace.think_space' target="_blank" rel = "nopener noreferrer">
                            <img className='w-24 sm:w-32' src={Dwhite} alt='get-icon' />
                        </a>
                        <a href='https://apps.apple.com/ng/app/thinkspace-app/id6445849614' target="_blank" rel= "noopener noreferrer">
                            <img className='w-24 sm:w-32' src={Get} alt='get-icon' />
                        </a>
                    </div>
                </div>
            </article>

            <article className='text-fp-white flex items-start flex-col  space-y-4'>
                <header>Pages</header>
                <ul className='text-fp-white space-y-2'>
                    <li>Home</li>
                    <li>About Us</li>
                    <li>Features</li>
                    <li>Terms of Service</li>
                    <li>Privacy Policy</li>
                </ul>
            </article>

            <article className='text-fp-white flex items-start flex-col  space-y-4'>
                <header>Contact information</header>
                <ul className='text-fp-white space-y-2'>
                    <li>+234 908 747 4348</li>
                    <li>Support-thinkspaces@fourpointslagos.com</li>
                    <li>Plot 9/10 Block 2 Oniru Chieftaincy Estate, Victoria Island 021189,Lagos</li>
                </ul>
            </article>

            <article className='text-fp-white flex items-start flex-col  space-y-4'>
                <header>Socials</header>
                <ul className='flex items-center text-fp-white space-x-4'>
                    <img className='' src={Social1} alt='get-icon' />
                    <img className='' src={Social2} alt='get-icon' />
                    <img className='' src={Social3} alt='get-icon' />
                    <img className='' src={Social4} alt='get-icon' />
                </ul>
            </article>
        </section>
    </div>
  )
}