import React from 'react'
import Dwhite from "../../Assets/Icons/dblack.svg"
import Get from "../../Assets/Icons/appb.svg"
import android from "../../Assets/Icons/android.svg"
import Phone from "../../Assets/Icons/phone2.svg"

export const Download = () => {
  return (
    <div className='w-full flex items-center justify-center flex-col space-y-12 md:px-[70px] xl:px-[144px] px-7'>
        <article className='flex items-center justify-center flex-col rounded-t-[40px] bg-fp-gray px-11 pt-11 space-y-24'>
            <header className='flex items-center justify-center flex-col space-y-4'>
                <p className='text-3xl md:text-5xl font-bold'>Download the App</p>
                <span className='text-center font-normal text-base'>
                    With our intuitive mobile app, you have the power to access a
                    variety of workspaces, conveniently book on-the-go, and enjoy seamless management of
                    your reservations.
                </span>
            </header>

            <section className='w-full flex items-center justify-center flex-col space-y-8'>
                <div className='flex items-center justify-center space-x-8'>
                    <a href='https://play.google.com/store/apps/details?id=com.thethinkspace.think_space' target="_blank" rel= "nooperner noreferrer">
                        <img className='w-24 sm:w-full' src={Dwhite} alt='get-icon' />
                    </a>

                    <a href='https://apps.apple.com/ng/app/thinkspace-app/id6445849614' target="_blank" rel="noopener noreferrer">
                        <img className='w-24 sm:w-full' src={Get} alt='get-icon' />
                    </a>
                </div>

                <div className='flex items-center justify-center space-x-8'>
                    <img className='w-24 sm:w-52 md:w-full' src={android} alt='get-icon' loading='lazy' />
                    <img className='w-24 sm:w-52 md:w-full' src={Phone} alt='get-icon' loading='lazy' />
                </div>
            </section>
        </article>
    </div>
  )
}