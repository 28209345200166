import './App.css';
import { Navbar } from './components/Layout/navbar';
import { Download } from './components/pages/download';
import { Experience } from './components/pages/experience';
import { Features } from './components/pages/features';
import { Footer } from './components/pages/footer';
import { Hero } from './components/pages/hero';
import { Pricing } from './components/pages/pricing';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Features />
      <Experience />
      <Pricing />
      <Download />
      <Footer />
    </div>
  );
}

export default App;
