import React from 'react'
import Grid from "../../Assets/Icons/grid0.svg"
import Grid1 from "../../Assets/Icons/grid1.svg"
import Grid2 from "../../Assets/Icons/grid2.svg"
import Grid3 from "../../Assets/Icons/grid3.svg"
import Grid4 from "../../Assets/Icons/grid4.svg"

export const Experience = () => {
  return (
    <div className='w-full flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between flex-col md:flex-row space-y-12 md:space-y-0 space-x-0 md:space-x-12 py-11 md:px-[70px] xl:px-[144px] px-7'>
        <header className='w-full md:w-1/4 text-3xl md:text-5xl font-bold'>
            Experience our elegant and sophisticated workspaces
        </header>

        <section className='w-full h-max md:w-3/4 grid grid-cols-2 md:grid-cols-3 gap-2'>
            <div className='w-full min-h-[300px] rounded-md col-span-2 md:row-span-2 md:col-span-1' style={{
                backgroundImage: `url(${Grid})`,
                backgroundRepeat: `no-repeat`,
                objectFit: `contain`,
                backgroundSize: "cover"
            }} />
            <div className='w-full min-h-[300px] rounded-md' style={{
                backgroundImage: `url(${Grid1})`,
                backgroundRepeat: `no-repeat`,
                objectFit: `contain`,
                backgroundSize: "cover"
            }} />
             
            <div className='w-full min-h-[300px] rounded-md' style={{
                backgroundImage: `url(${Grid2})`,
                backgroundRepeat: `no-repeat`,
                objectFit: `contain`,
                backgroundSize: "cover"
            }} />
            <div className='w-full min-h-[300px] rounded-md' style={{
                backgroundImage: `url(${Grid3})`,
                backgroundRepeat: `no-repeat`,
                objectFit: `contain`,
                backgroundSize: "cover"
            }} />
            <div className='w-full min-h-[300px] rounded-md' style={{
                backgroundImage: `url(${Grid4})`,
                backgroundRepeat: `no-repeat`,
                objectFit: `contain`,
                backgroundSize: "cover"
            }} />
        </section>
    </div>
  )
}