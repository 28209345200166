import React, { useState } from 'react'
import * as Icon from "react-feather"
import { MainLogo } from '../../Assets/Icons/generated';
// import Download from "../../Assets/Icons/download.svg"

export const Navbar = () => {

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(prev => !prev)
    }

  return (
    <div className='w-full fixed top-0 left-0 z-10'>
        <div className='relative h-16 bg-fp-main w-full flex items-center justify-between py-4 px-7 md:px-[70px] xl:px-[144px]'>

            <div className='flex items-center space-x-4 text-fp-white'>
                {/* <img src={Logo} alt="logo" /> */}
                <MainLogo />
                <div className='flex items-start flex-col'>
                    <h3 className='text-lg lg:text-xl font-bold'>Thinkspace</h3>
                    <span className='text-xs font-normal'>Fourpoints by Sheraton</span>
                </div>
            </div>
            <ul className={`bg-fp-main text-fp-white text-base space-x-0 space-y-6 lg:space-y-0 lg:space-x-12 lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-7 md:pl-[70px] transition-all duration-500 ease-in-out ${open ? 'top-16 ':'top-[-490px]'}`}>
                <NavItem children text="Home" />
                {/* <NavItem children text="About Us" /> */}
                <NavItem children text="Features" />
                <NavItem children text="Pricing" />
                {/* <img className='cursor-pointer' src={Download} alt='download-icon'/> */}
            </ul>
        
            <div onClick={handleToggle} className='flex items-center justify-center space-x-2 text-fp-white text-base absolute right-9 cursor-pointer lg:hidden'> 
                { !open ? <Icon.Menu size={20} /> : <Icon.X size={20} />}
            </div>
        </div>
    </div>
  )
}

export const NavItem = ({ text, children }) => {
    return (
        <a href={`#${text}`} className='py-2 lg:py-0 cursor-pointer flex items-center space-x-1'>
            <li className='text-fp-white font-medium text-base'>{text}</li>
        </a>
    )
}
