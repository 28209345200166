import React from 'react'
import heroImage from "../../Assets/Icons/heroImage.svg"
import Getit from "../../Assets/Icons/getit.svg"
import Appb from "../../Assets/Icons/dwhite.svg"

export const Hero = () => {
  return (
    <div id='Home' className='flex items-center justify-center mt-[64px] space-y-2 bg-fp-main min-h-[725px]'>
        <section className='py-11 relative space-x-0 w-full flex flex-col-reverse justify-start items-start md:space-x-12 md:flex-row md:px-[70px] xl:px-[144px] px-7'>
            <article data-aos="zoom-in" className='py-4 lg:w-1/2 w-full space-y-8'>
                <div className='flex items-start flex-col justify-start'>
                    <span className='text-[40px] leading-loose lg:text-5xl font-normal text-fp-white'>
                        Find Your Ideal Workspace with ThinkSpace
                    </span>
                </div>
                <p className='text-lg text-fp-white font-normal w-full'>
                    Discover a new level of convenience and productivity with ThinkSpace, the premier mobile
                    application for co-working space bookings. Created by Four Points Sheraton Lagos, our app
                    is designed to revolutionize the way you book and manage your workspaces.
                </p>

                <div className='space-y-4 sm:space-y-0 flex flex-col items-start sm:flex-row  sm:items-center space-x-0 sm:space-x-4'>
                    <a href='https://play.google.com/store/apps/details?id=com.thethinkspace.think_space' target="_blank" rel = "noopener noreferrer">
                        <img src={Getit} alt='get-icon'/>
                    </a>
                   <a href='https://apps.apple.com/ng/app/thinkspace-app/id6445849614' target="_blank" rel= "noopener noreferrer">
                    <img src={Appb} alt='get-icon'/>
                   </a>
                </div>
            </article>
            <img 
                data-aos="zoom-in"
                loading='lazy'
                className='hidden lg:block absolute right-0 w-full md:w-2/5' 
                src={heroImage} 
                alt="hero-banner" 
            />
                
        </section>
    </div>
  )
}