import React from 'react'
import Easy from "../../Assets/Icons/connect.svg"
import Manage from "../../Assets/Icons/manage.svg"
import Enhance from "../../Assets/Icons/heart.svg"
import Shield from "../../Assets/Icons/shield.svg"
import Network from "../../Assets/Icons/network.svg"
import Phone from "../../Assets/Icons/iphone.svg"

export const Features = () => {
    
    const features = [
        {
            id: 1,
            header: "Easy Booking Process",
            text: `Book workstations and meeting rooms with just a few taps.
            Choose from flexible hourly, daily, and monthly packages to suit your needs.
            Secure your booking by making payments directly within the app.
            `,
            icon: Easy
        },
        {
            id: 2,
            header: "Seamless Workspace Management",
            text: `Get a comprehensive view of your bookings and schedule through the
            intuitive dashboard.
            Effortlessly reschedule or cancel bookings, ensuring flexibility and
            convenience.
            Receive instant confirmation notifications to stay updated on your
            reservations..
            `,
            icon: Manage
        },
        {
            id: 3,
            header: "Enhanced User Experience",
            text: `Enjoy a user-friendly interface that makes navigating the app a breeze.
            Seamlessly switch between different features and sections with intuitive
            navigation.
            Access comprehensive help and support resources to assist you every step
            of the way.
            `,
            icon: Enhance
        },
        {
            id: 4,
            header: "Reliable Security and Privacy",
            text: `Trust in our commitment to safeguarding your personal information and
            payment details.
            Benefit from secure transactions and data encryption protocols.
            Rest easy knowing that your privacy is our top priority..
            `,
            icon: Shield
        },
        {
            id: 5,
            header: "Diverse Workspace Options",
            text: `Explore a wide range of thoughtfully designed workspaces and meeting
            rooms.
            Find the perfect space that matches your requirements, whether you need a
            quiet workstation or a collaborative meeting room.
            Enjoy comfortable and modern amenities to enhance your work experience.
            .
            `,
            icon: Network
        },
    ]

  return (
    <div id="Features" className='flex items-center justify-center flex-col space-y-12 py-11 md:px-[70px] xl:px-[144px] px-7'>
        <header className='text-3xl md:text-5xl font-bold'>
            Amazing features with Thinkspace
        </header>
        
        <section className='relative w-full flex items-center flex-col gap-12'>

            <div className='block xl:absolute'>
                <img src={Phone} alt='phone' loading='lazy' />
            </div>

            <div className='w-full flex flex-col items-center md:flex-row md:items-start justify-between space-y-12 md:space-y-0 space-x-0 md:space-x-4'>
                <FeatureCard 
                    icon={features[0].icon}
                    header={features[0].header}
                    text={features[0].text}
                />
                <FeatureCard 
                    icon={features[1].icon}
                    header={features[1].header}
                    text={features[1].text}
                />
            </div>

            <div className='w-full flex flex-col md:flex-row items-center justify-between space-y-12 md:space-y-0 space-x-0 md:space-x-4'>
                <FeatureCard 
                    icon={features[2].icon}
                    header={features[2].header}
                    text={features[2].text}
                />
                <FeatureCard 
                    icon={features[3].icon}
                    header={features[3].header}
                    text={features[3].text}
                />
            </div>
            <div className='w-full flex flex-col md:flex-row items-center justify-start xl:justify-center space-y-12 md:space-y-0 space-x-0 md:space-x-4 xl:mt-24'>
                <FeatureCard 
                    icon={features[4].icon}
                    header={features[4].header}
                    text={features[4].text}
                />
            </div>
        </section>
    </div>
  )
}

export const FeatureCard = ({ icon, header, text }) => {
    return (
        <div className='w-full md:max-w-[400px] flex items-start space-y-4 flex-col text-fp-black p-0 m-0'>
            <header className='flex items-start space-y-2 flex-col'>
                <img src={icon} alt='icon' />
                <h2 className='text-lg font-bold'>{header}</h2>
            </header>
            <p className='text-sm font-normal'>{text}</p>
        </div>
    )
}